// extracted by mini-css-extract-plugin
export var dark = "v_th";
export var darkcookie = "v_tj";
export var tintedcookie = "v_tk";
export var regularcookie = "v_tl";
export var darkbase = "v_tm";
export var tintedbase = "v_tn";
export var regularbase = "v_tp";
export var darkraw = "v_tq";
export var tintedraw = "v_tr";
export var regularraw = "v_ts";
export var darkchick = "v_tt";
export var tintedchick = "v_tv";
export var regularchick = "v_tw";
export var darkherbarium = "v_tx";
export var tintedherbarium = "v_ty";
export var regularherbarium = "v_tz";
export var darkholiday = "v_tB";
export var tintedholiday = "v_tC";
export var regularholiday = "v_tD";
export var darkoffline = "v_tF";
export var tintedoffline = "v_tG";
export var regularoffline = "v_tH";
export var darkorchid = "v_tJ";
export var tintedorchid = "v_tK";
export var regularorchid = "v_tL";
export var darkpro = "v_tM";
export var tintedpro = "v_tN";
export var regularpro = "v_tP";
export var darkrose = "v_tQ";
export var tintedrose = "v_tR";
export var regularrose = "v_tS";
export var darktimes = "v_tT";
export var tintedtimes = "v_tV";
export var regulartimes = "v_tW";
export var darkwagon = "v_tX";
export var tintedwagon = "v_tY";
export var regularwagon = "v_tZ";