// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "t_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "t_fR d_fR d_bz d_bJ";
export var menuDesign6 = "t_sM d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "t_sN d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "t_sP d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "t_sQ d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "t_sR d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "t_m7 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "t_nj d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "t_sS";
export var navbarItem = "t_m8 d_bx";
export var navbarLogoItemWrapper = "t_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "t_sT d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "t_sV d_gd d_by d_Z d_bs";
export var burgerToggle = "t_sW d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "t_sX d_gd d_by d_Z d_bs";
export var burgerInput = "t_sY d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "t_sZ d_f3 d_w d_H";
export var burgerLine = "t_s0 d_f1";
export var burgerMenuDesign6 = "t_s1 d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "t_s2 d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "t_s3 d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "t_s4 d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "t_s5 d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "t_s6 d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "t_s7 d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "t_s8 d_bC d_bP";
export var compact = "t_s9";
export var navDivided = "t_tb";
export var staticBurger = "t_tc";
export var menu = "t_td";
export var navbarDividedLogo = "t_tf";
export var nav = "t_tg";