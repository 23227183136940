// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "q_rc d_gS d_cw d_dv";
export var quoteParagraphCenter = "q_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "q_gV d_gV d_cw d_dx";
export var quoteRowLeft = "q_rd d_bG";
export var quoteRowCenter = "q_rf d_bD";
export var quoteRowRight = "q_rg d_bH";
export var quoteWrapper = "q_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "q_gR d_gR";
export var quoteExceptionSmall = "q_rh s_rh";
export var quoteExceptionNormal = "q_rj s_rj";
export var quoteExceptionLarge = "q_rk s_rk";
export var quoteAuthorExceptionSmall = "q_rl s_rl";
export var quoteAuthorExceptionNormal = "q_rm s_rm";
export var quoteAuthorExceptionLarge = "q_rn s_rn";