// extracted by mini-css-extract-plugin
export var alignDiscLeft = "p_qM d_fp d_bG d_dv";
export var alignLeft = "p_qh d_fp d_bG d_dv";
export var alignDiscCenter = "p_qN d_fq d_bD d_dw";
export var alignCenter = "p_bP d_fq d_bD d_dw";
export var alignDiscRight = "p_qP d_fr d_bH d_dx";
export var alignRight = "p_qj d_fr d_bH d_dx";
export var footerContainer = "p_qQ d_dW d_bW";
export var footerContainerFull = "p_qR d_dT d_bW";
export var footerHeader = "p_kf d_kf";
export var footerTextWrapper = "p_qS d_w";
export var footerDisclaimerWrapper = "p_km d_km d_ck";
export var badgeWrapper = "p_qT d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "p_qV d_bz d_bJ d_bN d_bL";
export var wide = "p_qW d_cy";
export var right = "p_qX d_bK";
export var line = "p_fk d_fl d_cv";
export var badgeDisclaimer = "p_qY d_bC d_bP d_bJ";
export var badgeContainer = "p_qZ d_bz d_bH d_bP";
export var badge = "p_q0";
export var padding = "p_q1 d_c7";
export var end = "p_q2 d_bH";
export var linkWrapper = "p_q3 d_dB";
export var link = "p_mC d_dB";
export var colWrapper = "p_q4 d_cx";
export var consent = "p_f d_f d_bC d_bP";
export var disclaimer = "p_q5 d_bz d_bJ";
export var media = "p_q6 d_bx d_dy";
export var itemRight = "p_q7";
export var itemLeft = "p_q8";
export var itemCenter = "p_q9";
export var exceptionWeight = "p_rb s_r1";