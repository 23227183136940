// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "r_rp d_bD";
export var storyRowWrapper = "r_hx d_hx d_bK";
export var storyLeftWrapper = "r_rq d_bz d_bP";
export var storyWrapperFull = "r_rr d_cD";
export var storyWrapperFullLeft = "r_pj d_cD d_bz d_bP d_bD";
export var contentWrapper = "r_mv d_hy";
export var storyLeftWrapperCenter = "r_rs d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "r_rt d_hF";
export var storyHeader = "r_rv d_hD d_w d_cs";
export var storyHeaderCenter = "r_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "r_hB d_hB d_by d_dw";
export var storyBtnWrapper = "r_rw d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "r_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "r_rx d_fg d_Z";
export var imageWrapperFull = "r_ry d_w d_H d_bf d_Z";
export var SubtitleSmall = "r_qc s_qc s_rz s_rM";
export var SubtitleNormal = "r_qd s_qd s_rz s_rN";
export var SubtitleLarge = "r_qf s_qf s_rz s_rP";
export var textLeft = "r_dv";
export var textCenter = "r_dw";
export var textRight = "r_dx";